import { termType } from '$schemas/terms';
import type { ParamMatcher } from '@sveltejs/kit';

export function isTermType(value: string): value is DB.Terms.Enum.TermType {
  return termType.enumValues.includes(value as any);
}

export const match: ParamMatcher = (param) => {
  return isTermType(param);
};
