import { blueprintType } from '$schemas/lms';
import type { ParamMatcher } from '@sveltejs/kit';

export function isBlueprintType(value: string): value is DB.LMS.Enum.BlueprintType {
  return blueprintType.enumValues.includes(value as any);
}

export const match: ParamMatcher = (param) => {
  return isBlueprintType(param);
};
