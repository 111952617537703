import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130')
];

export const server_loads = [0,39,15,40,17,18,24,2,9,3,31,33,34,12,5,7,4];

export const dictionary = {
		"/(landings)/(home)": [~122,[39]],
		"/(hub)/admin": [67,[15]],
		"/(hub)/admin/impersonation": [~68,[15]],
		"/(hub)/admin/stats": [69,[16]],
		"/(hub)/admin/stats/teams": [~70,[16]],
		"/(iam)/auth/edu": [~114,[37]],
		"/(iam)/auth/invite": [~115],
		"/(iam)/auth/password": [~116],
		"/(iam)/auth/recover": [~117,[36]],
		"/(iam)/auth/signin": [~118,[36]],
		"/(iam)/auth/signup": [~119,[36]],
		"/(landings)/championship": [~123,[40]],
		"/(landings)/championship/disciplines": [124,[40]],
		"/(landings)/championship/documents": [125,[40]],
		"/(hub)/console": [71,[17]],
		"/(hub)/console/cms/[resource_id]/richtext": [72,[18]],
		"/(hub)/console/cms/[resource_id]/text": [~73,[19]],
		"/(hub)/console/codes": [~74,[17]],
		"/(hub)/console/codes/[id]": [~75,[20]],
		"/(hub)/console/crm/feedback": [~76,[17]],
		"/(hub)/console/crm/partnership": [~77,[17]],
		"/(hub)/console/crm/subscription": [~78,[17]],
		"/(hub)/console/organizations": [~79,[17]],
		"/(hub)/console/organizations/[id]": [~80,[21]],
		"/(hub)/console/organizations/[id]/groups": [~81,[21]],
		"/(hub)/console/organizations/[id]/groups/[group_id]": [~82,[22]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/roles": [~83,[22]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/terms": [~84,[22]],
		"/(hub)/console/organizations/[id]/roles": [~85,[21]],
		"/(hub)/console/terms": [~86,[17]],
		"/(hub)/console/terms/[id]": [~87,[23]],
		"/(hub)/console/users": [~88,[17]],
		"/(hub)/dev": [89,[24],[25]],
		"/(hub)/dev/examples": [~90,[26]],
		"/(hub)/dev/examples/await": [91,[26]],
		"/(hub)/dev/examples/scheduler": [92,[26]],
		"/(hub)/dev/examples/seaweedfs": [93,[26]],
		"/(hub)/dev/examples/xapi": [94,[26]],
		"/(hub)/dev/examples/yjs": [95,[26]],
		"/(hub)/dev/landing": [96,[27]],
		"/(hub)/dev/playground": [~97,[28]],
		"/(hub)/dev/playground/buttons": [98,[28]],
		"/(hub)/dev/playground/confirm": [99,[28]],
		"/(hub)/dev/playground/dates": [100,[28]],
		"/(hub)/dev/playground/inputs": [~101,[28]],
		"/(hub)/dev/playground/modal": [102,[28]],
		"/(hub)/dev/playground/popover": [103,[28]],
		"/(hub)/dev/playground/switch": [104,[28]],
		"/(hub)/dev/playground/tabs copy": [106,[28]],
		"/(hub)/dev/playground/tabs": [105,[28]],
		"/(hub)/dev/playground/toasts": [107,[28]],
		"/(education)/events": [53,[8]],
		"/(landings)/faststart": [126],
		"/(education)/groups": [~54,[2]],
		"/(education)/groups/[id]": [~55,[9]],
		"/(education)/groups/[id]/terms": [~56,[9]],
		"/(education)/(home)/home": [~42,[3]],
		"/(education)/(home)/invitations_international": [~44,[3]],
		"/(education)/(home)/invitations_limited": [~45,[3]],
		"/(education)/(home)/invitations": [~43,[3]],
		"/legal/user-agreement": [130,[41]],
		"/(hub)/lms": [108,[29],[30]],
		"/(hub)/lms/[type=blueprint_type]": [~109,[29],[30]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/content": [111,[31,33,34],[32]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/materials": [112,[31,33],[32]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]": [~110,[31,33],[32]],
		"/(hub)/mentorship": [113,[35]],
		"/(landings)/opencup": [~127],
		"/(landings)/opencup/disciplines": [128],
		"/(landings)/opencup/documents": [129],
		"/(education)/org/[org_id]": [~57,[2]],
		"/(education)/org/[org_id]/events": [59,[11]],
		"/(education)/org/[org_id]/events/[term_id]/teams": [~60,[12]],
		"/(education)/org/[org_id]/events/[term_id]/teams/personal": [~62,[12]],
		"/(education)/org/[org_id]/events/[term_id]/teams/team": [63,[12]],
		"/(education)/org/[org_id]/events/[term_id]/teams/[group_id]": [~61,[12],[,13]],
		"/(education)/org/[org_id]/teams/[group_id]": [~64,[2]],
		"/(education)/org/[org_id]/[slug]": [~58,[10]],
		"/(education)/rewards": [~65,[2]],
		"/(education)/rewards/[reward_id]": [~66,[2],[,14]],
		"/(iam)/user": [~120,[38]],
		"/(iam)/user/security": [~121,[38]],
		"/(education)/[type=term_type]": [~46,[2]],
		"/(education)/[type=term_type]/international": [~49,[5]],
		"/(education)/[type=term_type]/international/events": [50,[6]],
		"/(education)/[type=term_type]/international/events/[term_id]/teams": [~51,[7]],
		"/(education)/[type=term_type]/international/events/[term_id]/teams/[group_id]": [~52,[7]],
		"/(education)/[type=term_type]/[slug]": [~47,[4]],
		"/(education)/[type=term_type]/[slug]/[blueprint_id]": [~48,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';